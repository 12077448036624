import React, { useState } from "react";
import styled from "@emotion/styled";
import { Player } from "video-react";
import { Modal } from "../containers/modal";

import theme from "../../styles/theme";
import { Button } from "../elements/button";
import { Cross } from "../elements/iconCross";

import "../../static/styles/video-react.css";

const VideoModalContent = styled.div`
  width: 75%;
  height: auto;
`;

const VideoPlayer = styled(Player)``;

const IconClose = styled(Cross)`
  background-color: transparent;
  height: 20px;
  transition: all 0.3 ease-in-out;
  display: inline-block;
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: ${theme.color.white};

  &:hover {
    cursor: pointer;
    color: ${theme.color.mint.primary};
  }
`;

export const VideoInModal = ({ src }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  return (
    <>
      <Button onClick={toggle} margin="1rem 0 0">
        Watch video
      </Button>
      <Modal isOpen={open} onBackgroundClick={toggle} onEscapeKeydown={toggle}>
        <VideoModalContent>
          <VideoPlayer autoPlay mute src={src} />
          <IconClose onClick={toggle} />
        </VideoModalContent>
      </Modal>
    </>
  );
};
