import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ColumnsPanel } from "../components/collections/ColumnsPanel";

export const ThreeColumnPannel = () => {
  const { wordpressPage } = useStaticQuery(graphql`
    query {
      wordpressPage(slug: { eq: "home" }) {
        acf {
          home_columns_panel_left {
            heading
            image {
              id
              source_url
              title
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 230) {
                    originalImg
                  }
                }
              }
            }
            link {
              url
              title
              target
            }
            text
          }
          home_columns_panel_centre {
            heading
            image {
              id
              source_url
              title
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 230) {
                    originalImg
                  }
                }
              }
            }
            link {
              url
              title
              target
            }
            text
          }
          home_columns_panel_right {
            heading
            image {
              id
              source_url
              title
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 230) {
                    originalImg
                  }
                }
              }
            }
            link {
              url
              title
              target
            }
            text
          }
        }
      }
    }
  `);
  return (
    <ColumnsPanel
      left={wordpressPage.acf.home_columns_panel_left}
      center={wordpressPage.acf.home_columns_panel_centre}
      right={wordpressPage.acf.home_columns_panel_right}
    />
  );
};
