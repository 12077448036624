import React, { Component } from "react";

import Layout from "../components/Layout";
import { SiteTitle } from "../components/elements/SiteTitle";

import { Footer } from "../components/collections/Footer";
import { LogoPanel } from "../panels/logoPanel";
import { CarouselPannel } from "../panels/carouselPannel";
import { HeroPanel } from "../panels/heroPanel";
import { ThreeColumnPannel } from "../panels/threeColumnPannel";
import { ContactPanel } from "../panels/contactPannel";
import { HomeTextPanel } from "../panels/homeTextPanel";

class Index extends Component {
  render() {
    // const home = this.props.data.wordpressPage.acf;

    return (
      <Layout transparentMenu>
        <SiteTitle>Home</SiteTitle>
        <HeroPanel />
        <HomeTextPanel />
        <ThreeColumnPannel />
        <CarouselPannel />
        <ContactPanel />
        <LogoPanel />
        <Footer />
      </Layout>
    );
  }
}

export default Index;
