import React from "react";
import { Layout } from "../elements/container";
import { Typography } from "../elements/Typography";

export const HomeTextPanel = props => {
  const {
    heading,
    content = `<p style="text-align: center;"><strong>What we do</strong></p>
<p style="text-align: center;"><span data-contrast="none">We design and build award-winning learning solutions that create real behaviour change and enhance workplace performance.
</span><br /><span data-contrast="none">Learn more </span><a href="http://inspiregroup.com.au/portfolio"><span data-contrast="none">here</span></a><span data-contrast="none">.</span><span data-ccp-props="{}"> </span></p>
&nbsp;
<p style="text-align: center;"><strong>Our Process</strong></p>
<p style="text-align: center;"><span data-contrast="none">We work with you to find out what your people need to know and how best to deliver it to them.</span></p>
<p style="text-align: center;"><span data-ccp-props="{}"> </span></p>`
  } = props;

  return (
    <div>
      <Layout container>
        <Typography
          display2
          heading2
          bold
          centre
          noMargin
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      </Layout>
      <Layout container narrow>
        <Typography
          centre
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      </Layout>
    </div>
  );
};

export default HomeTextPanel;
