import React from "react";
import styled from "@emotion/styled";

import theme from "../../styles/theme";
import { Card } from "../elements/card";
import { Typography } from "../elements/Typography";
import { Mask } from "../elements/Mask";
import { VideoInBG } from "./VideoInBG";
import { VideoInModal } from "./VideoInModal";
import bgVid from "../../static/videos/vid1.mp4";
import vid from "../../static/videos/vid2.mp4";

const HeroLayout = styled.section`
  height: 100vh;
  min-height: 800px;
  width: 100vw;
  display: flex;
  position: relative;
  background-color: ${theme.color.mint.primary};

  @media (max-width: ${theme.device.medium}) {
    height: auto;
    text-align: center;
  }
`;

const HeroCard = styled(Card)`
  z-index: 2;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -5vh;
`;

export const HeroPanel = props => {
  const {
    heading = "Hi, we’re Inspire Group",
    content = "We design inspiring, award-winning learning solutions to help your people thrive.",
    videoSrc = vid,
    bgVideoSrc = bgVid,
    gradient = "https://inspiregroup.co.nz/wp-content/uploads/2019/03/Hero-copy.svg"
  } = props;

  return (
    <HeroLayout>
      <HeroCard flex>
        <Typography heading1 display2 bold>
          {heading}
        </Typography>
        <Typography>{content}</Typography>
        <VideoInModal src={videoSrc} />
      </HeroCard>
      <VideoInBG src={bgVideoSrc} />
      <Mask bottom image src={gradient} />
    </HeroLayout>
  );
};
