import React from "react";
import styled from "@emotion/styled";

import theme from "../../styles/theme";
import { Card } from "./card";

import maskBottom from "../../static/images/mask-swirl-bottom.svg";
import maskTop from "../../static/images/mask-swirl-top.svg";

// Defaults to mask on top and bottom
// Leaves 1px transparent - possibly artwork issue
const StyledMask = styled(Card)`
  bottom: 0;

  z-index: 1;

  background-image: url(${maskBottom}), url(${maskTop});
  background-position: bottom, top;
  background-repeat: no-repeat;
  background-size: 100%;

  ${props =>
    props.bottom &&
    `
    background-image: url(${maskBottom});
    background-position: bottom;
    top: unset;
    bottom: -2px;
  `}

  ${props =>
    props.top &&
    `
    background-image: url(${maskTop});
    background-position: top;
  `}

  mix-blend-mode: ${props => props.blend && "hard-light"};

`;

const Gradient = styled(Card)`
  z-index: 0;

  background-image: linear-gradient(
    to right bottom,
    #ebabdf,
    #cbc2ff,
    #a2d9ff,
    #86ecff,
    #90fbfb
  );
  background-image: ${props => props.green && "none"};
  background-color: ${props => props.green && theme.color.mint.primary};

  opacity: 0.8;
`;

export const Mask = ({ transparent, image, src, ...rest }) => {
  if (transparent) {
    return (
      <>
        <Mask {...rest} absolute />
      </>
    );
  }

  if (image) {
    return (
      <>
        <StyledMask {...rest} absolute />
        <ImageMask
          {...rest}
          absolute
          blend
          style={{ backgroundImage: `url(${src})` }}
        />
      </>
    );
  }

  return (
    <>
      <StyledMask {...rest} absolute />
      {/* <Gradient {...rest} absolute /> */}
    </>
  );
};

export const ImageMask = styled(Mask)`
  background-image: none;
  background-position: unset;
  background-size: cover;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;
