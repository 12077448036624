import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import Plx from "react-plx";

import theme from "../../styles/theme";

import { Card } from "../elements/card";
import { Button } from "../elements/button";
import { Typography } from "../elements/Typography";
import { Mask } from "../elements/Mask";

const ContactLayout = styled.section`
  height: 100vh;
  width: 100vw;
  display: block;
  position: relative;
  background-color: ${theme.color.mint.primary};
  ${"" /* background-color: lime; */}
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;

  @media (max-width: ${theme.device.medium}) {
    height: 600px;
  }

  @media (max-width: ${theme.device.small}) {
    height: 50vh;
  }
`;

const ContactCard = styled(Card)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const parallaxDataLayer1 = [
  {
    start: 0,
    end: 4000,
    properties: [
      {
        startValue: 30,
        endValue: -10,
        unit: "vh",
        property: "translateY"
      }
    ]
  }
];
const parallaxDataLayer2 = [
  {
    start: 0,
    end: 4000,
    properties: [
      {
        startValue: 40,
        endValue: -20,
        unit: "vh",
        property: "translateY"
      }
    ]
  }
];

const ParallaxLayer1 = styled(Plx)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: ${theme.device.small}) {
      ${"" /* object-fit: fill; */}
    }
  }
`;
const ParallaxLayer2 = styled(ParallaxLayer1)`
  z-index: -2;

  img {
    @media (max-width: ${theme.device.small}) {
      object-fit: cover;
      width: auto;
      height: 100%;
      overflow: hidden;
    }
  }
`;

export class ContactPanelWithParallax extends React.Component {
  render() {
    const {
      image: { url } = {
        url: "https://inspiregroup.co.nz/wp-content/uploads/2018/12/BG-01-2.svg"
      },
      panelHeading = "Get in touch",
      panelContent = "We’d love to know how we can help you.",
      parallaxLayer1 = {
        image:
          "https://inspiregroup.co.nz/wp-content/uploads/2018/12/Front_Tree-01-1.svg"
      },
      parallaxLayer2 = {
        image:
          "https://inspiregroup.co.nz/wp-content/uploads/2018/12/Middle-01-2.svg"
      }
    } = this.props;

    return (
      <ContactLayout
        className="parallax"
        style={{
          backgroundImage: `url(${url})`
        }}
      >
        <ContactCard flex>
          <Typography heading2 display3 noMargin lineHeight="1rem">
            {panelHeading}
          </Typography>
          <Typography
            display2
            bold
            centre
            style={{
              margin: "1em 0"
            }}
            dangerouslySetInnerHTML={{
              __html: panelContent
            }}
          />
          <Link to="/contact">
            <Button secondary margin="1rem 0 0">
              Get in touch
            </Button>
          </Link>
        </ContactCard>
        <ParallaxLayer1 parallaxData={parallaxDataLayer1}>
          <img src={parallaxLayer1.image} alt="" />
        </ParallaxLayer1>
        <ParallaxLayer2 parallaxData={parallaxDataLayer2}>
          <img src={parallaxLayer2.image} alt="" />
        </ParallaxLayer2>
        <Mask transparent />
      </ContactLayout>
    );
  }
}
