import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import { Typography } from "../elements/Typography";

import theme from "../../styles/theme";

import { Layout } from "../elements/container";
import { Card } from "../elements/card";
import { Button } from "../elements/button";
import { List, Item } from "../elements/List";

const ColumnsLayout = styled(Layout)`
  padding-top: 4rem;
`;

const ColumnsListCard = styled(Card)``;

const ColumnsList = styled(List)`
  display: flex;
  justify-content: space-between;
  text-align: center;

  @media (max-width: ${theme.device.medium}) {
    flex-direction: column;
  }

  img {
    width: auto;
    height: 140px;
  }

  ${ColumnsListCard} {
    _padding: 2rem;
    width: 100%;

    p {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    button {
      margin-top: 2rem;
    }
  }
`;

const PanelItem = styled(Item)`
  flex: 1 1 33%;
`;

function getUrlPath(string) {
  const url = new URL(string);
  return url.pathname;
}

const Panel = ({ data }) => {
  let sr;
  if (data.image.localFile && data.image.localFile.childImageSharp) {
    sr = data.image.localFile.childImageSharp.fluid.originalImg.src;
  }

  return (
    <PanelItem>
      <ColumnsListCard>
        <img
          src={sr}
          alt={data.image.alt_text}
          title={data.image.text}
          style={{ marginBottom: 24 }}
        />
        <Typography bold heading3 display3>
          {data.heading}
        </Typography>
        <Typography noMargin>{data.text}</Typography>
        <Link to={getUrlPath(data.link.url)}>
          <Button>More</Button>
        </Link>
      </ColumnsListCard>
    </PanelItem>
  );
};

export class ColumnsPanel extends React.Component {
  render() {
    const { left, center, right } = this.props;

    return (
      <ColumnsLayout container>
        <ColumnsList>
          <Panel data={left} />
          <Panel data={center} />
          <Panel data={right} />
        </ColumnsList>
      </ColumnsLayout>
    );
  }
}
